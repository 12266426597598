import React from 'react';
import Layout from '../../../components/layout/layout'
import Header from '../../../components/header/header'
import Nav from '../../../components/nav/nav'
import Footer from '../../../components/footer/footer'

import catalogueCover from '../../../assets/images/albums/installations/curatorial-projects/state-of-the-earth-catalogue-cover.jpg'
import earthMonthPoster from '../../../assets/images/albums/installations/curatorial-projects/earth-month-final-poster.jpg'

export default () => (
  <Layout>
    <Header />
    <Nav />
    <main>
      <p>
        EARTH MONTH: Environmental Awareness
      </p>

      <p>
        April is EARTH MONTH, a time when people around the world celebrate our planet and take action to protect it. Every year on April 22, EARTH DAY is the largest civic event in the world, with more than one billion people participating. Fort Point Arts Community welcomes this observance by inspiring and raising awareness about the importance of climate change.
      </p>

      <p>
        “EARTH MONTH: Environmental Awareness project”  an exhibition curated by Liliana Folta, celebrates the collective effort for substantial environmental and social change through artistic mixed media, installations and public art. The official organizational theme for EARTH DAY  2025 is “renewable energy”, although the project extends to a variety of environmental and social issues in which everything is connected.
      </p>

      <p>
        The exhibition presents visual forms of intellectual understanding and engages viewers on an emotional level of consciousness to protect our Planet. This exciting collective effort with local artists focuses on social, emotional and environmental ecology. Artists, members of FPAC, guests and the i3C Artists Group (Inspiring Change for the Climate Crisis) are invited to explore the relation on how social behavior affects the people, land, water and air. They have a deep connection with repurposed and recycled materials that are polluting the planet. This diverse display of artworks shines a light on the  challenges humanity and the environment are facing.
      </p>

      <p>
        <span>Exhibition: "EARTH MONTH: Environmental Awareness"</span><br />
        <span>Curator: Liliana Folta</span><br />
        <span>Main venue: Fort Point Arts Community Art Space @ The Envoy - 70 Sleeper St. Boston</span><br />
        <span>Dates: April 1 to 30 - 2025 -  Opening reception @ Art Space,  April 12 - 6pm to 8pm</span><br />
      </p>

      <p>
        <span>Collaboration venues in Fort Point with gallery stroll will be 20 minutes each.</span><br />
        <span>Starts @ Midway Gallery/Project Room at 4:30pm -15 Channel Center St. Boston</span><br />
        <span>Continues with 249 A St Gallery and 259 A st. Gallery. Walk ends with the opening reception at FPAC Art Space.</span><br />
      </p>

      <p>
        Folta's project is made possible thanks to the support of a generous Private Donor.
      </p>

      <a
        href="https://www.fortpointarts.org/events/earth-month/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={earthMonthPoster} alt="Poster for the Earth Month exhibition." />
      </a>

      <br />
      <br />
      <br />

      <p>
        "State of the Earth" - 21 Women Artists on Global Biodiversity Crisis
      </p>
      <p>
        Liliana Folta<br />
        <span style={{ fontStyle: 'italic' }}>
          Interdisciplinary Artist and Independent Curator
        </span><br />
        <span style={{ fontStyle: 'italic' }}>
          Advocate of native plants
        </span>
      </p>
      <p>
        This catalog compiles the works of 21 women artists from different parts of the world, whose new proposals represent a concern for the biodiversity of their homeland or the places they chose to live.
      </p>
      <p>
        A year and a half in the making, the project was presented at the Midway Gallery in Boston, Massachussetts, September 2023.
      </p>
      <p>
        This project is partnering with the Pontificia Universidad Católica Argentina - Buenos Aires, for the extended exhibition in their virtual gallery.<br /><br />
        Co-curated with Verónica Parselis, Professor of Philosophy and Aesthetics.
      </p>
      <a 
        href="https://biblioteca.uca.edu.ar/expo/visiones/visiones.html" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        https://biblioteca.uca.edu.ar/expo/visiones/visiones.html
      </a><br /><br />
      <a
        href="https://midwayartiststudios.org/exhibitions/state-of-emergency-state-of-the-earth/"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://midwayartiststudios.org/exhibitions/state-of-emergency-state-of-the-earth/
      </a><br /><br />
      <p>Catalog:&nbsp;</p>
      <a
        href="https://heyzine.com/flip-book/1aa0b6c23c.html"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={catalogueCover} alt="Interactive flipbook for State of the Earth catalogue" />
      </a>
    </main>
    <Footer />
  </Layout>
)
